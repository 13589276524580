import { ProductResponseDocsModel } from '@app/core/models/product/productResponseDocs.model';
import { createAction, props } from '@ngrx/store';

export const addSelectedPlpProduct = createAction(
  '[PLP] Selected product added',
  props<{ products: ProductResponseDocsModel }>()
);

export const removeSelectedProductsById = createAction(
  '[PLP] Selected product removed based on id',
  props<{ productId: string }>()
);

export const removeAllSelectedPlpProducts = createAction('[PLP] Selected products removed');
